/*
=============== 
Variables
===============
*/

:root {
  --clr-primary-1: #2b1f00;
  --clr-primary-2: #6f4f00;
  --clr-primary-8: #d4d1c5;
  --clr-primary-9: #e3e1d8;
  --clr-primary-10: #f1f0ec;
  --clr-gray-1: #121212;
  --clr-gray-3: #595959;
  --clr-gray-5: #808080;

  --max-width: 500px;
  --font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  margin-top: 5rem;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
  color: var(--clr-primary-1);
  background-color: var(--clr-primary-10);
  font-size: 1rem;
}

header {
  display: flex;
  flex-flow: column;
  row-gap: 0.75rem;
}

main {
  display: flex;
  flex-flow: column;
  row-gap: 1.75rem;
  margin: 0 3rem;
  max-width: var(--max-width);
}

h1 span {
  background-size: 100% 8%;
  background-image: linear-gradient(90deg, #f7ff00, #db36a4);
  background-repeat: no-repeat;
  background-position: bottom;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 0.3rem;
}

h3 {
  margin-bottom: 0.3rem;
}

li {
  list-style: none;
}

.bullet::before {
  content: "";
  min-width: 6px;
  height: 6px;
  margin: 11px;
  border-radius: 50%;
  background-color: #808080;
}

.wip::after {
  content: "WIP";
  margin-left: 0.3rem;
  font-size: 0.75rem;
  color: var(--clr-gray-5);
}
#projects a {
  color: var(--clr-gray-1);
}

#projects p {
  margin-left: 1.75rem;
  color: var(--clr-gray-3);
}

a,
Link,
li p {
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  color: var(--clr-gray-5);
  margin-bottom: 0.3rem;
  padding: 2px;
  width: fit-content;
  cursor: initial;
}

a span,
Link span {
  margin-top: 2px;
  border-bottom: #999999 2px solid;
  cursor: pointer;
}

a span:hover,
Link span:hover {
  border-bottom: 2px solid rgba(18, 18, 18, 0);
}

img {
  width: 1.25rem;
  margin: 0.25rem;
  cursor: pointer;
}

footer {
  max-width: var(--max-width);
  margin: 5rem 0 2.5rem;
  color: var(--clr-gray-5);
  margin: 4rem 3rem 1rem;
}

form {
  display: grid;
  grid-template-rows: 2;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  border: 0.1rem solid var(--clr-primary-9);
  text-overflow: ellipsis;
}
input {
  margin: 0.3rem 0 0.6rem;
}

.link {
  text-overflow: initial;
}

p a,
footer a,
footer Link,
footer span {
  padding: 0;
  display: unset;
  cursor: pointer;
}

button {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  border: 0.1rem solid var(--clr-primary-8);
  background-color: var(--clr-primary-9);
}

label {
  font-weight: 500;
}
